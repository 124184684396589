export const Logo = ({color = 'primary', className}: {color?: 'primary' | 'white'; className?: string}) => {
  const fill = color === 'primary' ? '#669BCB' : '#fff';
  return (
    <svg viewBox="0 0 822 193" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M153.657 79.8284C156.971 79.8284 159.68 77.138 159.432 73.8336C158.196 57.423 151.911 41.7398 141.366 28.9782C129.542 14.6701 113.103 4.92911 94.8744 1.43075C76.646 -2.06762 57.7683 0.895291 41.4881 9.80988C25.2079 18.7245 12.543 33.0335 5.6717 50.2758C-1.19964 67.5181 -1.84789 86.616 3.83846 104.285C9.52481 121.953 21.1903 137.088 36.8285 147.086C52.4667 157.084 71.1001 161.32 89.5237 159.066C105.956 157.055 121.305 149.994 133.489 138.931C135.943 136.703 135.826 132.887 133.421 130.608L127.645 125.135C125.239 122.855 121.459 122.984 118.951 125.15C110.001 132.876 98.9244 137.81 87.0999 139.257C73.2822 140.947 59.3071 137.77 47.5785 130.272C35.8498 122.773 27.1007 111.422 22.836 98.1705C18.5712 84.9191 19.0574 70.5957 24.2109 57.664C29.3644 44.7322 38.8631 34.0004 51.0732 27.3145C63.2833 20.6286 77.4416 18.4064 91.1129 21.0302C104.784 23.6539 117.114 30.9597 125.981 41.6908C133.57 50.8739 138.217 62.0742 139.399 73.8381C139.731 77.1352 142.386 79.8284 145.7 79.8284H153.657Z"
        fill={fill}
      />
      <path
        d="M72.2859 72.2857C68.1203 76.4512 68.1203 83.205 72.2859 87.3706C76.4515 91.5362 83.2052 91.5362 87.3708 87.3706C91.5364 83.205 91.5364 76.4512 87.3708 72.2857C83.2052 68.1201 76.4515 68.1201 72.2859 72.2857ZM152.114 152.114C147.949 156.28 147.949 163.033 152.114 167.199C156.28 171.365 163.034 171.365 167.199 167.199C171.365 163.033 171.365 156.28 167.199 152.114C163.034 147.948 156.28 147.948 152.114 152.114ZM78.4142 81.2423L158.243 161.071L161.071 158.242L81.2426 78.4139L78.4142 81.2423Z"
        fill={fill}
      />
      <path
        d="M244.5 136.272V96.1814H263.864V166H245.273V153.318H244.545C242.97 157.409 240.349 160.697 236.682 163.181C233.045 165.666 228.606 166.909 223.364 166.909C218.697 166.909 214.591 165.848 211.045 163.727C207.5 161.606 204.727 158.59 202.727 154.681C200.758 150.772 199.758 146.09 199.727 140.636V96.1814H219.091V137.181C219.121 141.303 220.227 144.56 222.409 146.954C224.591 149.348 227.515 150.545 231.182 150.545C233.515 150.545 235.697 150.015 237.727 148.954C239.758 147.863 241.394 146.257 242.636 144.136C243.909 142.015 244.53 139.393 244.5 136.272Z"
        fill={fill}
      />
      <path
        d="M311.17 167.363C303.989 167.363 297.807 165.909 292.625 163C287.474 160.06 283.504 155.909 280.716 150.545C277.928 145.151 276.534 138.772 276.534 131.409C276.534 124.227 277.928 117.924 280.716 112.5C283.504 107.075 287.428 102.848 292.489 99.8177C297.58 96.7874 303.549 95.2723 310.398 95.2723C315.004 95.2723 319.292 96.0147 323.261 97.4996C327.261 98.9541 330.746 101.151 333.716 104.09C336.716 107.03 339.049 110.727 340.716 115.181C342.383 119.606 343.216 124.787 343.216 130.727V136.045H284.261V124.045H324.989C324.989 121.257 324.383 118.787 323.17 116.636C321.958 114.484 320.277 112.803 318.125 111.59C316.004 110.348 313.534 109.727 310.716 109.727C307.777 109.727 305.17 110.409 302.898 111.772C300.655 113.106 298.898 114.909 297.625 117.181C296.352 119.424 295.701 121.924 295.67 124.681V136.09C295.67 139.545 296.307 142.53 297.58 145.045C298.883 147.56 300.716 149.5 303.08 150.863C305.443 152.227 308.246 152.909 311.489 152.909C313.64 152.909 315.61 152.606 317.398 152C319.186 151.393 320.716 150.484 321.989 149.272C323.261 148.06 324.231 146.575 324.898 144.818L342.807 146C341.898 150.303 340.034 154.06 337.216 157.272C334.428 160.454 330.822 162.939 326.398 164.727C322.004 166.484 316.928 167.363 311.17 167.363Z"
        fill={fill}
      />
      <path
        d="M413.943 116.09L396.216 117.181C395.913 115.666 395.261 114.303 394.261 113.09C393.261 111.848 391.943 110.863 390.307 110.136C388.701 109.378 386.777 109 384.534 109C381.534 109 379.004 109.636 376.943 110.909C374.883 112.151 373.852 113.818 373.852 115.909C373.852 117.575 374.519 118.984 375.852 120.136C377.186 121.287 379.473 122.212 382.716 122.909L395.352 125.454C402.14 126.848 407.201 129.09 410.534 132.181C413.867 135.272 415.534 139.333 415.534 144.363C415.534 148.939 414.186 152.954 411.489 156.409C408.822 159.863 405.155 162.56 400.489 164.5C395.852 166.409 390.504 167.363 384.443 167.363C375.201 167.363 367.837 165.439 362.352 161.59C356.898 157.712 353.701 152.439 352.761 145.772L371.807 144.772C372.383 147.59 373.777 149.742 375.989 151.227C378.201 152.681 381.034 153.409 384.489 153.409C387.883 153.409 390.61 152.757 392.67 151.454C394.761 150.121 395.822 148.409 395.852 146.318C395.822 144.56 395.08 143.121 393.625 142C392.17 140.848 389.928 139.969 386.898 139.363L374.807 136.954C367.989 135.59 362.913 133.227 359.58 129.863C356.277 126.5 354.625 122.212 354.625 117C354.625 112.515 355.837 108.651 358.261 105.409C360.716 102.166 364.155 99.6662 368.58 97.9086C373.034 96.1511 378.246 95.2723 384.216 95.2723C393.034 95.2723 399.974 97.1359 405.034 100.863C410.125 104.59 413.095 109.666 413.943 116.09Z"
        fill={fill}
      />
      <path
        d="M464.864 96.1814V110.727H422.818V96.1814H464.864ZM432.364 79.4541H451.727V144.545C451.727 146.333 452 147.727 452.545 148.727C453.091 149.697 453.849 150.378 454.818 150.772C455.818 151.166 456.97 151.363 458.273 151.363C459.182 151.363 460.091 151.287 461 151.136C461.909 150.954 462.606 150.818 463.091 150.727L466.136 165.136C465.167 165.439 463.803 165.787 462.045 166.181C460.288 166.606 458.152 166.863 455.636 166.954C450.97 167.136 446.879 166.515 443.364 165.09C439.879 163.666 437.167 161.454 435.227 158.454C433.288 155.454 432.333 151.666 432.364 147.09V79.4541Z"
        fill={fill}
      />
      <path
        d="M508.273 167.363C501.212 167.363 495.106 165.863 489.955 162.863C484.833 159.833 480.879 155.621 478.091 150.227C475.303 144.803 473.909 138.515 473.909 131.363C473.909 124.151 475.303 117.848 478.091 112.454C480.879 107.03 484.833 102.818 489.955 99.8177C495.106 96.7874 501.212 95.2723 508.273 95.2723C515.333 95.2723 521.424 96.7874 526.545 99.8177C531.697 102.818 535.667 107.03 538.455 112.454C541.242 117.848 542.636 124.151 542.636 131.363C542.636 138.515 541.242 144.803 538.455 150.227C535.667 155.621 531.697 159.833 526.545 162.863C521.424 165.863 515.333 167.363 508.273 167.363ZM508.364 152.363C511.576 152.363 514.258 151.454 516.409 149.636C518.561 147.787 520.182 145.272 521.273 142.09C522.394 138.909 522.955 135.287 522.955 131.227C522.955 127.166 522.394 123.545 521.273 120.363C520.182 117.181 518.561 114.666 516.409 112.818C514.258 110.969 511.576 110.045 508.364 110.045C505.121 110.045 502.394 110.969 500.182 112.818C498 114.666 496.349 117.181 495.227 120.363C494.136 123.545 493.591 127.166 493.591 131.227C493.591 135.287 494.136 138.909 495.227 142.09C496.349 145.272 498 147.787 500.182 149.636C502.394 151.454 505.121 152.363 508.364 152.363Z"
        fill={fill}
      />
      <path
        d="M618.068 96.1814L593.659 166H571.841L547.432 96.1814H567.886L582.386 146.136H583.114L597.568 96.1814H618.068Z"
        fill={fill}
      />
      <path
        d="M657.545 167.363C650.364 167.363 644.182 165.909 639 163C633.849 160.06 629.879 155.909 627.091 150.545C624.303 145.151 622.909 138.772 622.909 131.409C622.909 124.227 624.303 117.924 627.091 112.5C629.879 107.075 633.803 102.848 638.864 99.8177C643.955 96.7874 649.924 95.2723 656.773 95.2723C661.379 95.2723 665.667 96.0147 669.636 97.4996C673.636 98.9541 677.121 101.151 680.091 104.09C683.091 107.03 685.424 110.727 687.091 115.181C688.758 119.606 689.591 124.787 689.591 130.727V136.045H630.636V124.045H671.364C671.364 121.257 670.758 118.787 669.545 116.636C668.333 114.484 666.652 112.803 664.5 111.59C662.379 110.348 659.909 109.727 657.091 109.727C654.152 109.727 651.545 110.409 649.273 111.772C647.03 113.106 645.273 114.909 644 117.181C642.727 119.424 642.076 121.924 642.045 124.681V136.09C642.045 139.545 642.682 142.53 643.955 145.045C645.258 147.56 647.091 149.5 649.455 150.863C651.818 152.227 654.621 152.909 657.864 152.909C660.015 152.909 661.985 152.606 663.773 152C665.561 151.393 667.091 150.484 668.364 149.272C669.636 148.06 670.606 146.575 671.273 144.818L689.182 146C688.273 150.303 686.409 154.06 683.591 157.272C680.803 160.454 677.197 162.939 672.773 164.727C668.379 166.484 663.303 167.363 657.545 167.363Z"
        fill={fill}
      />
      <path
        d="M702.227 166V96.1814H721V108.363H721.727C723 104.03 725.136 100.757 728.136 98.545C731.136 96.3026 734.591 95.1814 738.5 95.1814C739.47 95.1814 740.515 95.242 741.636 95.3632C742.758 95.4844 743.742 95.6511 744.591 95.8632V113.045C743.682 112.772 742.424 112.53 740.818 112.318C739.212 112.106 737.742 112 736.409 112C733.561 112 731.015 112.621 728.773 113.863C726.561 115.075 724.803 116.772 723.5 118.954C722.227 121.136 721.591 123.651 721.591 126.5V166H702.227Z"
        fill={fill}
      />
      <path
        d="M768.682 192.181C766.227 192.181 763.924 191.984 761.773 191.59C759.652 191.227 757.894 190.757 756.5 190.181L760.864 175.727C763.136 176.424 765.182 176.803 767 176.863C768.849 176.924 770.439 176.5 771.773 175.59C773.136 174.681 774.242 173.136 775.091 170.954L776.227 168L751.182 96.1814H771.545L786 147.454H786.727L801.318 96.1814H821.818L794.682 173.545C793.379 177.303 791.606 180.575 789.364 183.363C787.152 186.181 784.349 188.348 780.955 189.863C777.561 191.409 773.47 192.181 768.682 192.181Z"
        fill={fill}
      />
    </svg>
  );
};
